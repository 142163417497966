import React from 'react'
import { Grid } from '@mui/material'
import anos from './dege.png'
import { BsTwitterX } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import c1 from './phantom.png'
import c2 from './solana.png'
import c3 from './rads.webp'
import c4 from './deb.png'
import Marquee from "react-fast-marquee";
import ch1 from './c1.png'
import ch2 from './unh.png'
import c from './chart.png'
import b from './buy.png'
import t from './telegram.png'
import tw from './twitter.png'
import t1 from './te.png'
import tw2 from './tw.png'


import Monk from './lom.png'
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 1200
});

function Desktop() {
  return (
    <div className='container'>
      <br/>
          <Grid container className='backall'>
      
          <Grid item md={2.4} xs={12} lg={2.4} className='centerall'>
          <br/>
         <a href='https://dexscreener.com/solana/6mffrbpa44mghz3mkkzqugwswuqqjvvxeqqzqnb6jdq5' className='colorofnav'>
         <img src={c} style={{width:"110px" , position:"relative", bottom:"20px"}}/>
         </a>
         </Grid>
         <Grid item md={2.4} xs={12} lg={2.4} className='centerall'>
          <br/>
         <a href='https://t.me/SolMonke420' className='colorofnav'>
         <img src={t} style={{width:"110px" , position:"relative", bottom:"20px"}}/>
         </a>
         </Grid>
         <Grid item md={2.4} xs={12} lg={2.4} className='centerall'>
          <br/>
         <p className='colorofnavs'>
       $ Monke
         </p>
         </Grid>
         <Grid item md={2.4} xs={12} lg={2.4} className='centerall'>
          <br/>
         <a href='https://x.com/SOLmonke420' className='colorofnav'>
         <img src={tw} style={{width:"110px" , position:"relative", bottom:"20px"}}/>
         </a>
         </Grid>
         <Grid item md={2.4} xs={12} lg={2.4} className='centerall'>
          <br/>
         <a href='https://raydium.io/swap/?inputMint=sol&outputMint=HQSTKbXjNBPGJsAhXijCVu18CGLCHaUnKvuEiZdkbagh' className='colorofnav'>
         <img src={b} style={{width:"110px" , position:"relative", bottom:"20px"}}/>
         </a>
         </Grid>
          </Grid>
       <br/> <br/> <br/> <br/>

       <Grid container spacing={2}>
   
       <Grid item md={6} xs={12} lg={6} className='centerall'>
     <br/>  <br/><br/><div className="item" data-aos="fade-up">    <h1 className='h1_head'>Monke</h1></div>
   
       <br/>
       <div style={{display:"flex"}} className='centerall' >
       <div className="item" data-aos="fade-up"><a href='https://t.me/SolMonke420'>
<img src={t1} style={{width:"50px" , position:"relative", bottom:"20px"}}/>
</a></div>

&nbsp; 
&nbsp;    
<div className="item" data-aos="fade-up"><a href='https://x.com/SOLmonke420'>
<img src={tw2} style={{width:"50px" , position:"relative", bottom:"20px"}}/>
</a></div>



       </div>
<br/>
<div className="item" data-aos="fade-right">
<div className='divca'>

<h2 className='ca'> HQSTKbXjNBPGJsAhXijCVu18CGLCHaUnKvuEiZdkbagh </h2>

</div>

</div>




          </Grid>
       <Grid item md={6} xs={12} lg={6} className='centerall'>
       <div data-aos="fade-left">   <img src={Monk} style={{width:"80%" , borderRadius:"100px"}}/></div>
     
         </Grid>
       </Grid>
   
       
     
     
   
          

       
       <br/>  <br/>  <br/>  <br/>
    </div>
  )
}

export default Desktop