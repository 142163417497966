import React, { useState } from "react";
import musicFile from "./noot.mp3";
import { Modal} from "react-bootstrap";
import { useMediaQuery } from "@mui/material";
import Marquee from "react-fast-marquee";
import Desktop from "./Desktop";
import Desktopthree from "./Desktopthree";
import DesktopHow from './DesktopHowtoBuy'
import Mobile from "./Mobile";

import Mobilethree from "./Mobilethree";

import Mobilethrees from "./MH";

import monke from './monkes.png'
import md from './md.png'

const Home = () => {
  const [showModal, setShowModal] = useState(true);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));


  const handleYesClick = () => {
    // Code to play music
    const audio = new Audio(musicFile);
    audio.loop = true;
    audio.play();
  };



  return (
    <div className="mainContent">


<div className="garden">
      {/* THIS JAVASCRIPT CODE IS FOR Header of the Park Page*/}

      {/* <Slider /> */}
      {!smallScreen && (
        <div className="desk">
           <div className="desks">
        <Desktop/>

        <Marquee className='backofit'>
       <p className='fotx'> &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; </p> <p className='fotx'> &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; </p>
  </Marquee>
        <img src={monke} style={{width:"100%"}} className="makeitfix"/>
        <Marquee className='backofit'>
       <p className='fotx'> &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; </p> <p className='fotx'> &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; </p>
  </Marquee>
     
<DesktopHow/>
        <Desktopthree/>
        </div>
     </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Desktop View when you press on CustomSlider with ctrl and right arrow you move towards the Slider Page Desktop View*/}
      {smallScreen && (
          <div className="desk">
          <div className="desks">
         <Mobile/>
         <Marquee className='backofit'>
       <p className='fotx'> &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; </p>
  </Marquee>
        <img src={monke} style={{width:"100%"}} className="makeitfix"/>
        <Marquee className='backofit'>
       <p className='fotx'> &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp; $Monke &nbsp;&nbsp; &nbsp;&nbsp; </p>
  </Marquee>
     
<Mobilethrees/>
         <Mobilethree/>
       </div>  </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Mobile View when you press on MobileSlider with ctrl and right arrow you move towards the Slider Page Mobile View*/}
    </div>
     
      

   
    </div>
  );
};

export default Home;
