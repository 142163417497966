import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material'
import m1 from './mn1.png'
import m2 from './mn2.png'
import m3 from './mn3.png'
import Game from './MonkGame'
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 2000
});
function DesktopHowtoBuy() {
    const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className='container'>
<br/>
        <Grid container spacing={2}>

        <Grid item md={4} lg={4} xs={12} sm={12}>
        <div className="item" data-aos="fade-up"> <img src={m1} style={{width:"100%" , borderRadius:"10px"}}/></div>


</Grid>
<Grid item md={4} lg={4} xs={12} sm={12}>
<div className="item" data-aos="fade-up"><img src={m2} style={{width:"100%" , borderRadius:"10px"}}/></div>


</Grid>
<Grid item md={4} lg={4} xs={12} sm={12}>
<div className="item" data-aos="fade-up"><img src={m3} style={{width:"100%" , borderRadius:"10px"}}/></div>


</Grid>
        </Grid>
<br/>
<a href='https://raydium.io/swap/?inputMint=sol&outputMint=HQSTKbXjNBPGJsAhXijCVu18CGLCHaUnKvuEiZdkbagh'><div className='centerall'>
<div className="item" data-aos="fade-up"><button role="button" class="golden-button">
  <span class="golden-text">Buy Monke</span>
</button></div>


</div></a>



<Grid container spacing={2}>
   
   <Grid item md={12} xs={12} lg={12} className='centerall'>
 <br/>  <br/><div className="item" data-aos="fade-up">   <h1 className='h1_headv'>How to Buy</h1></div>

 
 
      
<br/>

<div>
  
  <div className="item" data-aos="fade-up"><Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className='backofaccor'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          
          <Typography sx={{ color: 'text.secondary' }} className='cf'>1. Create a wallet</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='cf'>
          Download Phantom or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension by going to phantom.app.
          </Typography>
        </AccordionDetails>
      </Accordion></div>
      
      <br/>
      <div className="item" data-aos="fade-up"><Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className='backofaccor'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
        
          <Typography sx={{ color: 'text.secondary' }}  className='cf'>
          2. Get some SOL
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography  className='cf'>
          Have SOL in your wallet to switch to $Monke. If you don’t have any SOL, you can buy SOL from an exchange or cross chain swap and send it to your wallet.
          </Typography>
        </AccordionDetails>
      </Accordion></div>
      
      <br/>
      <div className="item" data-aos="fade-up"><Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className='backofaccor'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
        
          <Typography sx={{ color: 'text.secondary' }} className='cf'>
          3. Go to Raydium
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='cf'>
          Connect to Raydium . Go raydium.io in google chrome or on the browser inside your Phantom app. Connect your wallet. Paste the $Monke token address into Raydium and confirm the swap. When Phantom prompts you for a wallet signature, sign.
          </Typography>
        </AccordionDetails>
      </Accordion></div>
      
      <br/>
      <div className="item" data-aos="fade-up"><Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className='backofaccor'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel3bh-header"
        >
        
          <Typography sx={{ color: 'text.secondary' }} className='cf'>
          4. Swap SOL for $Monke
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='cf'>
          Now paste Monke Contract Address on Raydium and swap your Sol to $Monke

          </Typography>
        </AccordionDetails>
      </Accordion></div>
      

      <br/>
      <br/>
    </div>

      </Grid>
   
   </Grid>
   <br/>
      <br/>
<Game/>
<br/>
      <br/>   <br/>
      <br/>   <br/>
      <br/>   <br/>
      <br/>   <br/>
      <br/>   <br/>
      <br/>   <br/>
      <br/>   <br/>
      <br/>   <br/>
      <br/>   <br/>
      <br/>
    </div>
  )
}

export default DesktopHowtoBuy