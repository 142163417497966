import React from 'react'

function Game() {
  return (
    <div><div style={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}>
    <iframe
      src="https://monke-beige.vercel.app/"
      title="Embedded Site"
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100vh',
        border: 'none'
      }}
      allowFullScreen
    />
  </div>
  
  
  </div>
  )
}

export default Game