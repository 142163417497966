import React from 'react'
import { Grid } from '@mui/material'
import base from './basein.png'
import bases from './social.jpeg'
import { BsTwitterX } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import t1 from './te.png'
import tw2 from './tw.png'
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 1500
});


function Desktopthree() {
  return (
    <div >

<div className='footerx'>
      <br/>
<div className='container'>
  
<Grid container>
<Grid container>
        
        <Grid item md={12} xs={12} lg={12} className='centerall'>
          <br/>          <br/>
          <div className="item" data-aos="fade-up"> <h2 style={{color:"#fff"}} className='disxx'>  Community   </h2></div>
       
        <br/>
        </Grid>

        <Grid item md={12} xs={12} lg={12} className='centerall'>
         <div style={{display:"flex"}} className='centerall' >
       <div className="item" data-aos="fade-up"><a href='https://t.me/SolMonke420'>
<img src={t1} style={{width:"50px" , position:"relative", bottom:"20px"}}/>
</a></div>

&nbsp; 
&nbsp;    
<div className="item" data-aos="fade-up"><a href='https://x.com/SOLmonke420'>
<img src={tw2} style={{width:"50px" , position:"relative", bottom:"20px"}}/>
</a></div>



       </div>
        
         </Grid>
        <Grid item md={4} xs={4} lg={4} className='centerall'>
      
        </Grid>
        <Grid item md={4} xs={4} lg={4} className='centerall'>
       <img src={bases} style={{width:"100%" , borderRadius:"10px"}}/>
        </Grid>
        <Grid item md={4} xs={4} lg={4} className='centerall'>
      
        </Grid>
         </Grid>
        
          </Grid>
<br/>
        

          <br/>


</div>


      </div>


      <div className='footer'>
      <br/>
<div className='container'>
<Grid container>
        
         <Grid item md={4} xs={4} lg={4} className='centerall'>
       
         </Grid>
       
         <Grid item md={4} xs={4} lg={4} className='centerall'>
       
         </Grid>
          </Grid>
<br/>
        
<Grid container>
        
         <Grid item md={12} xs={12} lg={12} className='centerall'>
       <h2 style={{color:"#000"}} className='dis'>Disclaimer</h2>
        <p className='dist'>Disclaimer: Information about $MONKE (Monke Coin) provided here is for informational purposes only and not financial advice. Cryptocurrency investments, like $MONKE, carry risks such as market volatility. Users should research and consult professionals before investing. Creators of $MONKE disclaim responsibility for any financial losses.</p>
        
         </Grid>
          </Grid>
          <br/>


</div>


      </div>
   
    </div>
  )
}

export default Desktopthree