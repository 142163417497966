import React from 'react'
import { Grid } from '@mui/material'
import anos from './dege.png'
import { BsTwitterX } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import c1 from './phantom.png'
import c2 from './solana.png'
import c3 from './rads.webp'
import c4 from './deb.png'
import Marquee from "react-fast-marquee";
import ch1 from './c1.png'
import ch2 from './unh.png'
import c from './chart.png'
import b from './buy.png'
import t from './te.png'
import tw from './tw.png'

import Monk from './lom.png'
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 1500
});

function Desktop() {
  return (
    <div className='container'>
      <br/>
          <Grid container className='backallx'>
      
       
         <Grid item md={2.4} xs={6} lg={2.4} className='centerall'>
   
        
          <a href='https://dexscreener.com/solana/6mffrbpa44mghz3mkkzqugwswuqqjvvxeqqzqnb6jdq5' className='colorofnavx'>
          <img src={c} style={{width:"110px" , position:"relative", top:"9px"}}/>
         </a>
         
         </Grid>
         <Grid item md={2.4} xs={6} lg={2.4} className='centerall'>
        
         <a href='https://raydium.io/swap/?inputMint=sol&outputMint=HQSTKbXjNBPGJsAhXijCVu18CGLCHaUnKvuEiZdkbagh' className='colorofnavx'>
         <img src={b} style={{width:"110px" , position:"relative", top:"9px"}}/>
         </a>
         
         </Grid>
        
      
        
       
          </Grid>
       <br/> 

       <Grid container spacing={2}>
   
       <Grid item md={6} xs={12} lg={6} className='centerall'>
     <br/>  <br/><br/>
     <div className="item" data-aos="fade-up">   <h1 className='h1_headm'>Monke </h1></div>
    
   
       <div style={{display:"flex"}} className='centerall' >

&nbsp;    

<a href='https://x.com/SOLmonke420'>
<div className="item" data-aos="fade-up"><img src={tw} style={{width:"50px" , position:"relative", top:"9px"}}/></div>

</a>
&nbsp; 
&nbsp;    
<a href='https://t.me/SolMonke420'>
<div className="item" data-aos="fade-up"><img src={t} style={{width:"50px" , position:"relative", top:"9px"}}/></div>

</a>
&nbsp; 
    
      
 &nbsp; 


       </div>


<br/>

          </Grid>
       <Grid item md={6} xs={12} lg={6} className='centerall'>
       <div className="item" data-aos="fade-up"> <img src={Monk} style={{width:"100%" , borderRadius:"100px"}}/></div>
       <br/>  <br/>
<div className="item" data-aos="fade-up"><div className='divca'>

<h2 className='cax'> HQSTKbXjNBPGJsAhXijCVu18CGLCHaUnKvuEiZdkbagh</h2>

</div></div>
         </Grid>
       </Grid>
   
       
     
     
   
          

       
       <br/>  <br/>  
    </div>
  )
}

export default Desktop