import React, { useState, useEffect } from 'react';
import { ThemeProvider } from "@mui/material/styles";
import { Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import theme from "./Theme";
import { Grid } from '@mui/material';

import load from './Mon.gif'
import "./lines.scss";

function LoadingScreen() {
  return (
    <div className="loading-screen">
      <Grid container>
       
          <Grid item md={12} xs={12} lg={12} className='centerall'>    <br/>     <br/><br/>
                <img src={load} className='image_anosl'/>
                <br/>
                <h1 className='font1'> Monke </h1>
         </Grid>
          </Grid>
      {/* You can add additional loading indicators here */}
    </div>
  );
}

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulating a delay for loading (you can replace this with your actual loading logic)
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 4500); // 2000 milliseconds (2 seconds) for demonstration purposes

    return () => clearTimeout(loadingTimeout);
  }, []);

  return (
    <div className="cursor">
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <ThemeProvider theme={theme}>
          <Routes>
            <Route
              path="/"
              element={<Home />}
            />
          </Routes>
        </ThemeProvider>
      )}
    </div>
  );
}

export default App;
